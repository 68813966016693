import React from "react"
import { graphql } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Img from "gatsby-image"
import { Link } from "gatsby"

import Layout from "../components/layout"


const Uslugi = ({ data }) => {
  return (
    <Layout>
      <div className="mt-8">
        <div className="bg-transparent">
          <h2
            className="font-bold text-2xl md:text-3xl lg:text-4xl xl:text-4xl"
            style={{
              fontFamily: `Montserrat`,
              color: `white`,
              padding: `1rem`,
            }}
          >
            УСЛУГИ
          </h2>
        </div>
      </div>
      <div>
        <div className="mt-20">
          {data.allContentfulUslugi.nodes.map(uslugi => (
            <div key={uslugi.id}>
              <div className="w-full flex flex-col mb-6 md:flex-row">
                <div className="flex justify-center items-center">
                  <Img
                    fluid={uslugi.image.fluid}
                    style={{
                      width: `25rem`,
                      height: `25rem`,
                    }}
                    imgStyle={{ objectFit: "contain" }}
                  />
                </div>
                <div className="p-6 ">
                  {" "}
                  <h3
                    className="text-xl md:text-2xl lg:text-2xl xl:text-2xl"
                    style={{
                      fontFamily: `Montserrat`,
                      color: `#F1F1F2`,
                    }}
                  >
                    {uslugi.title}
                  </h3>
                  <p
                    className="pt-4 text-sm md:text-sm lg:text-sm xl:text-sm"
                    style={{
                      fontFamily: `Montserrat`,

                      color: `#F1F1F2`,
                    }}
                  >
                    {documentToReactComponents(uslugi.description.json)}
                  </p>
                  <Link to={`/${uslugi.slug}`}>
                    <button
                      className="mt-4 bg-transparent text-white uppercase font-semibold py-2 px-4 border border-gray-400 rounded shadow"
                      style={{
                        fontFamily: `Montserrat`,
                        fontSize: `0.7rem`,
                      }}
                    >
                      подробнее
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default Uslugi

export const PageQuery = graphql`
  query Uslugi {
    allContentfulUslugi {
      nodes {
        image {
          fluid(maxWidth: 600, quality: 35) {
            ...GatsbyContentfulFluid
          }
        }
        title
        slug
        id
        description {
          json
        }
      }
    }
  }
`
